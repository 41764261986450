/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  PATIENT_COMPLETED_DATA,
  EXCEL_COMPLETE_DATA,
  EXCELVACCINE_COMPLETE_DATA,
  PATIENT_COMPLETED_COUNT,
  PATIENT_COMPLETED_OLDCOUNT,
  PATIENT_FILTER_COUNT,
  PATIENTOLD_FILTER_COUNT,
  PATIENTLIST_COMPLETED_COUNT,
  PATIENTOLDLIST_COMPLETED_COUNT,
  PATIENTOLDLIST_COMPLETED_DATA,
  PATIENT_COMPLETED_VACC_DATA,
  PATIENTLIST_COMPLETED_VACC_DATA,
  PATIENTLIST_COMPLETED_DATA,
  PATIENT_COMPLETED_PATIENT_NAME,
  PATIENT_SEARCH,
  PATIENT_OLDERX_SEARCH,
  PATIENT_VACC_SEARCH,
  DISEASE_SEARCH,
  FORGOT_PASWD_SUCCESS,
  OTP_TIME,
  FORGOT_PASWD_ERROR,
  DOCTOR_APPROVE_STATUS,
  ERX_DATA_HTML,
  WORD_PREVIEW_CLIENT,
  ERX_DATA_HTML_NO_DATA,
  PATIENT_VACC_FILTER_COUNT,
  PATIENT_COMPLETED_VACC_COUNT,
  PATIENT_FILTER_VACC_COUNT,
  AGE_VACCINATION_LIST,
} from '../../shared/constants/ActionTypes';

const initialState = {
  patientlist: null,
  exceldata: [],
  vaccineExceldata: [],
  patientcount: null,
  patientvacccount: null,
  patientoldcount: null,
  patientfiltercount: null,
  patientvaccfiltercount: null,
  patientfiltervacccount: null,
  patientsearch: '',
  patientolderxsearch: '',
  patientvaccsearch: '',
  diseasesearch: '',
  forgotpaswdsuccess: '',
  forgotpaswderror: '',
  doctorApprovedStatus: 0,
  patientnames: [],
  erxhtmldata: [],
  erxhtmlnodata: '',
  otp_time: '',
  patientVacclist: null,
  word_Preview_Client: [],
  list_patient_data: null,
  list_patient_olddata: null,
  list_patient_vaccdata: null,
  list_patient_count: null,
  list_patient_oldcount: null,
  age_vaccine_list: [],
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    //Assign user data
    case WORD_PREVIEW_CLIENT:
      return {
        ...state,
        word_Preview_Client: action.payload,
      };
    case PATIENT_COMPLETED_DATA:
      return {
        ...state,
        patientlist: action.payload,
      };
    case PATIENT_COMPLETED_VACC_DATA:
      return {
        ...state,
        patientVacclist: action.payload,
      };
    case PATIENT_COMPLETED_VACC_COUNT:
      return {
        ...state,
        patientvacccount: action.payload,
      };
    case EXCEL_COMPLETE_DATA:
      return {
        ...state,
        exceldata: action.payload,
      };
    case EXCELVACCINE_COMPLETE_DATA:
      return {
        ...state,
        vaccineExceldata: action.payload,
      };
    case PATIENT_COMPLETED_COUNT:
      return {
        ...state,
        patientcount: action.payload,
      };
    case PATIENT_COMPLETED_OLDCOUNT:
      return {
        ...state,
        patientoldcount: action.payload,
      };
    case PATIENT_FILTER_COUNT:
      return {
        ...state,
        patientfiltercount: action.payload,
      };
    case PATIENT_VACC_FILTER_COUNT:
      return {
        ...state,
        patientvaccfiltercount: action.payload,
      };
    case PATIENTOLD_FILTER_COUNT:
      return {
        ...state,
        patientoldfiltercount: action.payload,
      };
    case PATIENT_FILTER_VACC_COUNT:
      return {
        ...state,
        patientfiltervacccount: action.payload,
      };
    case PATIENTLIST_COMPLETED_DATA:
      return {
        ...state,
        list_patient_data: action.payload,
      };
    case PATIENTOLDLIST_COMPLETED_DATA:
      return {
        ...state,
        list_patient_olddata: action.payload,
      };
    case PATIENTLIST_COMPLETED_VACC_DATA:
      return {
        ...state,
        list_patient_vaccdata: action.payload,
      };
    case PATIENTLIST_COMPLETED_COUNT:
      return {
        ...state,
        list_patient_count: action.payload,
      };
    case PATIENTOLDLIST_COMPLETED_COUNT:
      return {
        ...state,
        list_patient_oldcount: action.payload,
      };
    case PATIENT_COMPLETED_PATIENT_NAME:
      return {
        ...state,
        patientnames: action.payload,
      };
    case PATIENT_SEARCH:
      return {
        ...state,
        patientsearch: action.payload,
      };
    case PATIENT_OLDERX_SEARCH:
      return {
        ...state,
        patientolderxsearch: action.payload,
      };
    case PATIENT_VACC_SEARCH:
      return {
        ...state,
        patientvaccsearch: action.payload,
      };
    case DISEASE_SEARCH:
      return {
        ...state,
        diseasesearch: action.payload,
      };
    case FORGOT_PASWD_SUCCESS:
      return {
        ...state,
        forgotpaswdsuccess: action.payload,
      };
    case OTP_TIME:
      return {
        ...state,
        otp_time: action.payload,
      };
    case FORGOT_PASWD_ERROR:
      return {
        ...state,
        forgotpaswderror: action.payload,
      };
    case DOCTOR_APPROVE_STATUS:
      return {
        ...state,
        doctorApprovedStatus: action.payload,
      };
    case ERX_DATA_HTML:
      return {
        ...state,
        erxhtmldata: action.payload,
      };
    case ERX_DATA_HTML_NO_DATA:
      return {
        ...state,
        erxhtmlnodata: action.payload,
      };
    case AGE_VACCINATION_LIST:
      return {
        ...state,
        age_vaccine_list: action.payload,
      };
    default:
      return state;
  }
};
export default patientReducer;
