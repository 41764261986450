/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Dashboard api calling
 ************/

import React from 'react';
import {
  FETCH_ERROR,
  FETCH_START,
  HOSPITAL_SEARCH_DATA,
  FETCH_SUCCESS,
  GET_DOCTOR_EDITDATA,
  DOCTOR_SPECIALIZATION,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  STATE_MASTER,
  DOCTOR_SEARCH_ARRAY,
  DOCTOR_SEARCH_DATA,
  ERX_OPERATOR_NOTES,
  resulttitle,
  CHANGE_MOBILENO_RES,
  bloodsugarList,
  SET_DOCTOR_ID_NAME,
  DOCTOR_SUCCESS_MSG,
  SHOW_MESSAGE,
  DASHBOARD_PENDING_PATIENT_DATA,
  DASHBOARD_ERX_DATA,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  UNIT_DATA,
  PATIENT_SUCCESS_MSG,
  DELETE_MEDCINE_SUCCESS_MSG,
  ERX_IMG_ARR,
  ERX_IMG_OCR_ARR,
  INVESTIGATION_CATEGORY_DATA,
  REPORT_DETAILS,
  TAG_CATEGORY_DATA,
  BUCKET_CAPTCHA,
  INVESTIGATION_DISEASE_DATA,
  TAG_DISEASE_DATA,
  PATIENT_LISTS,
  TODAY_APPOINTMENT_DATA,
  SEARCH_REPORTS_DATA,
  SEARCH_PATIENT_DATA,
  SEARCH_PATIENT_OLDDATA,
  SEARCH_DISEASE_DATA,
  SEARCH_SYMPTOMS_DATA,
} from '../../shared/constants/ActionTypes';
import IntlMessages from '../../@crema/utility/IntlMessages';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import {error} from 'winston';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Dashboard.js';
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';
//specialization and doctor specialization event
export const onGetSpecialization = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    var value = body;
    jwtAxios
      .post('v1/doctorDtls/specializationjwt', body)
      .then((data) => {
        try {
          body['jwtToken'] = data.data.body['token'];
          jwtAxios
            .post('v1/doctorDtls/specialization', body)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: DOCTOR_SPECIALIZATION,
                  payload: data.data.body.specialization,
                });
                dispatch({
                  type: DOCTOR_SUBSPECIALIZATION,
                  payload: data.data.body.subspecialization,
                });
                jwtAxios
                  .post('v1/pateRxMgmt/mastersjwt', value)
                  .then((data) => {
                    value['jwtToken'] = data.data.body['token'];
                    jwtAxios
                      .post('v1/pateRxMgmt/masters', value)
                      .then((data) => {
                        dispatch({
                          type: DEPARTMENT_MASTER,
                          payload: data.data.body.deptList,
                        });
                        dispatch({
                          type: DOCTOR_QUALIFICATION,
                          payload: data.data.body.quaList,
                        });
                        dispatch({
                          type: STATE_MASTER,
                          payload: data.data.body.stateList,
                        });
                        dispatch({
                          type: resulttitle,
                          payload: data.data.body.resulttitle,
                        });
                        dispatch({
                          type: bloodsugarList,
                          payload: data.data.body.bloodsugarList,
                        });
                      })
                      .catch((error) => {
                        dispatch({type: FETCH_ERROR, payload: error.message});
                        dispatch(
                          onWriteCloudWatchLog(
                            streamname,
                            filename,
                            'onGetSpecialization',
                            '0',
                            error,
                          ),
                        );
                      });
                  })
                  .catch((error) => {
                    dispatch({type: FETCH_ERROR, payload: error.message});
                    dispatch(
                      onWriteCloudWatchLog(
                        streamname,
                        filename,
                        'onGetSpecialization',
                        '0',
                        error,
                      ),
                    );
                  });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSpecialization',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSpecialization',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSpecialization',
            '0',
            error,
          ),
        );
      });
  };
};

// Get ongetbucketandcaptcha  data
export const ongetbucketandcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/ongetbucketandcaptcha')
        .then((data) => {
          if (data.data.status === 200) {
            // dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: BUCKET_CAPTCHA,
              payload: data.data.body,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetbucketandcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetbucketandcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};

//Decrypt password
const decryptpassword = function ({data}) {
  var decipher = crypto.createDecipher(algorithm, password);
  var dec = decipher.update(data, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
};

export const onGetSearchHospital = (value, doctorId) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      // get jwt token based on input
      jwtAxios
        .post('v1/doctorDtls/searchHospitalJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            // get the data
            jwtAxios
              .post('v1/doctorDtls/searchHospital', value)
              .then((data) => {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: HOSPITAL_SEARCH_DATA,
                  payload: data.data.body,
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchHospital',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchHospital',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchHospital',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchHospital',
          '0',
          err,
        ),
      );
    }
  };
};

// Get ongetbucketandcaptcha  data
export const ongetEncryptbucketcaptcha = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getUploadPresCredentialsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getUploadPresCredentials', value)
              .then((data) => {
                if (data.data.status === 200) {
                  let data1 = data.data.body;
                  let res = {
                    type: decryptpassword({data: data1?.type}),
                    accessKey: decryptpassword({data: data1?.accessKey}),
                    secretAccessKey: decryptpassword({
                      data: data1?.secretAccessKey,
                    }),
                    bucketName: decryptpassword({data: data1?.bucketName}),
                    region: decryptpassword({data: data1?.region}),
                    filePath: decryptpassword({data: data1?.filePath}),
                    captchaKey: decryptpassword({data: data1?.captchaKey}),
                  };
                  dispatch({
                    type: BUCKET_CAPTCHA,
                    payload: res,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              });
          } catch (error) {
            dispatch({type: FETCH_ERROR, payload: error.message});
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'ongetEncryptbucketcaptcha',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'ongetEncryptbucketcaptcha',
          '0',
          err,
        ),
      );
    }
  };
};

// save doctor api
export const saveDoctorDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call User list Token  - Get Token
    try {
      jwtAxios
        .post('v1/doctorDtls/createjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];

            jwtAxios
              .post('v1/doctorDtls/create', value)
              .then((data) => {
                dispatch({
                  type: SET_DOCTOR_ID_NAME,
                  payload: {
                    DocID: data.data.body.doctorId,
                    DocName: data.data.body.doctorName,
                    RegisterNo: data.data.body.registerNo,
                    EmailId: data.data.body.email,
                  },
                });
                localStorage.setItem('DocID', data.data.body.ddoctorId);
                localStorage.setItem('DocName', data.data.body.doctorName);
                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: {},
                });
                dispatch({
                  type: DOCTOR_SUCCESS_MSG,
                  payload: data.data.message,
                });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: 'Doctor saved successfully',
                });
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'saveDoctorDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'saveDoctorDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'saveDoctorDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'saveDoctorDetails',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onGetPendingPatInfn = ({userId, patId, userCode, erxid}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {userId, patId, userCode, erxid};
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/getpatientjwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              const bodytoken = {userId, patId, userCode, erxid, jwtToken};
              jwtAxios
                .post('v1/patientDtls/getpatient', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body[0];
                    dispatch({
                      type: DASHBOARD_PENDING_PATIENT_DATA,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending erx information
export const onGeteRxInfn = ({userId, erxReqId, userCode}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    let body = {userId, erxReqId, userCode};

    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/geteRxDtlsJwt', body)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              const jwtToken = data.data.body['token'];
              let bodytoken = {userId, erxReqId, jwtToken, userCode};
              // alert(erxReqId)
              jwtAxios
                .post('v1/pateRxMgmt/geteRxDtls', bodytoken)
                .then((data) => {
                  if (data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.body;
                    dispatch({
                      type: DASHBOARD_ERX_DATA,
                      payload: getdata,
                    });

                    //Get Erx image and OCr data
                    if (getdata) {
                      if (getdata.erxImageOCR.length > 0) {
                        var imgurl = [];
                        var imgocr = [];
                        for (var i = 0; i < getdata.erxImageOCR.length; i++) {
                          //Push only images
                          imgurl.push({url: getdata.erxImageOCR[i].erxUrl});
                          //Push Image with ocr data and extension
                          imgocr.push({
                            url: getdata.erxImageOCR[i].erxUrl,
                            ocrdata: getdata.erxImageOCR[i].ocrData,
                            extension: getdata.erxImageOCR[i].urlExtension,
                            rownumber: getdata.erxImageOCR[i].rownumber,
                          });
                        }

                        dispatch({
                          type: ERX_IMG_ARR,
                          payload: imgurl,
                        });
                        dispatch({
                          type: ERX_IMG_OCR_ARR,
                          payload: imgocr,
                        });
                      }
                    }
                    dispatch({
                      type: GET_DOCTOR_EDITDATA,
                      payload: {},
                    });
                    if (getdata.rxdtls.length > 0) {
                      dispatch({
                        type: DASHBOARD_RX_DETAILS,
                        payload: getdata.rxdtls[0],
                      });
                      dispatch({
                        type: SET_DOCTOR_ID_NAME,
                        payload: {
                          DocID: getdata.rxdtls[0].doctorId,
                          DocName: getdata.rxdtls[0].docName,
                          RegisterNo: getdata.rxdtls[0].registerno,
                          EmailId: getdata.rxdtls[0].email,
                        },
                      });
                    }

                    if (getdata.docobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOC_OBSV,
                        payload: getdata.docobsv[0],
                      });
                    }
                    if (getdata.labobsv.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_LAB_OBSV,
                        payload: getdata.labobsv[0],
                      });
                    }

                    if (getdata.patientvital.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_PATIENT_VITAL,
                        payload: getdata.patientvital[0],
                      });
                    }

                    if (getdata.docprocedure.length > 0) {
                      dispatch({
                        type: DASHBOARD_ERX_DOCPROCEDURE,
                        payload: getdata.docprocedure,
                      });
                    }

                    if (getdata.medicinedtls.length > 0) {
                      var arraydata = getdata.medicinedtls.map((e, i) => {
                        return {
                          saltName: e.saltName,
                          medName: e.medName,
                          mn: e.mn,
                          af: e.an,
                          en: e.en,
                          nt: e.nt,
                          unit: e.uomId,
                          unitcode: e.uomId,
                          quantity: e.dosage,
                          refferal: e.referral,
                          route: e.route,
                          advice: e.advice,
                          duration: e.duration,
                          frequency: e.frequency,
                          seqno: e.txnNo,
                        };
                      });
                      dispatch({
                        type: DASHBOARD_ERX_MEDICINE_DETAIL,
                        payload: arraydata,
                      });
                    }
                    if (getdata.genericmedicinedtls.length > 0) {
                      var arraydatageneric = getdata.genericmedicinedtls.map(
                        (e, i) => {
                          return {
                            saltName: e.saltName,
                            medName: e.medName,
                            mn: e.mn,
                            af: e.an,
                            en: e.en,
                            nt: e.nt,
                            unit: e.uomId,
                            unitcode: e.uomId,
                            quantity: e.dosage,
                            refferal: e.referral,
                            refferalHindi: e.referralHindi,
                            route: e.route,
                            advice: e.advice,

                            duration: e.duration,

                            frequency: e.frequency,
                            seqno: e.txnNo,
                          };
                        },
                      );
                      dispatch({
                        type: DASHBOARD_GRX_MEDICINE_DETAIL,
                        payload: arraydatageneric,
                      });
                    }

                    if (getdata.rxdtls.length > 0) {
                      var patientId = getdata.rxdtls[0].patientId;

                      if (
                        getdata.rxdtls[0].doctorId !== '' &&
                        getdata.rxdtls[0].doctorId !== '0' &&
                        getdata.rxdtls[0].doctorId !== null &&
                        getdata.rxdtls[0].doctorId !== undefined
                      ) {
                        dispatch(
                          onGetDoctorInfo({
                            userId: body.userId,
                            erxId: body.erxReqId,
                            userCode: body.userCode,
                          }),
                        );
                      }
                      dispatch(
                        onGetPendingPatInfn({
                          userId: body.userId,
                          patId: patientId,
                          userCode: body.userCode,
                          erxid: body.erxReqId,
                        }),
                      );
                    }
                  } else {
                    dispatch({
                      type: FETCH_ERROR,
                      payload: <IntlMessages id='message.somethingWentWrong' />,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGeteRxInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGeteRxInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxInfn', '0', err),
      );
    }
  };
};
// doctor tab seacrh doctor using mobile No/register
export const onGetSearchArray = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/searchDoctorRegisterJwt', value)
      .then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/searchDoctorRegister', value)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: DOCTOR_SEARCH_ARRAY,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSearchArray',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchArray',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSearchArray',
            '0',
            error,
          ),
        );
      });
  };
};

//search match doctor detail,s

// doctor tab seacrh doctor using mobile No/register
export const onGetSearchDoctor = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/searchDoctorJwt', value)
      .then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/searchDoctor', value)
            .then((data) => {
              dispatch({type: FETCH_SUCCESS});
              dispatch({
                type: DOCTOR_SEARCH_DATA,
                payload: data.data.body,
              });
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetSearchDoctor',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchDoctor',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetSearchDoctor',
            '0',
            error,
          ),
        );
      });
  };
};
// doctor tab edit load event
export const onGetDoctorInfo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    // get jwt token based on input
    jwtAxios
      .post('v1/doctorDtls/getDoctorJwt', value)
      .then((data) => {
        try {
          value['jwtToken'] = data.data.body['token'];
          // get the data
          jwtAxios
            .post('v1/doctorDtls/getDoctor', value)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({
                  type: GET_DOCTOR_EDITDATA,
                  payload: data.data.body,
                });
              } else {
                dispatch({
                  type: FETCH_ERROR,
                  payload: <IntlMessages id='message.somethingWentWrong' />,
                });
              }
            })
            .catch((error) => {
              dispatch({type: FETCH_ERROR, payload: error.message});
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetDoctorInfo',
                  '0',
                  error,
                ),
              );
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetDoctorInfo',
              '0',
              err,
            ),
          );
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetDoctorInfo',
            '0',
            error,
          ),
        );
      });
  };
};

//get erxNotes
export const onGeteRxNotes = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getOperatorNotesjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getOperatorNotes', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  const getdata = data.data.body;
                  dispatch({
                    type: ERX_OPERATOR_NOTES,
                    payload: getdata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxNotes',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxNotes',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxNotes',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxNotes', '0', err),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onChangeMobileNo = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/docMgmt/changeDocMobileNojwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/docMgmt/changeDocMobileNo', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.message;
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: data.data.message,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

//Get dashboard Pending patient information
export const onChangeemailid = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Patient data Token  - Get Token
    try {
      jwtAxios
        .post('v1/docMgmt/changeDocEmailjwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call Patient data - Get patient data using token
            try {
              value['jwtToken'] = data.data.body['token'];
              jwtAxios
                .post('v1/docMgmt/changeDocEmail', value)
                .then((data) => {
                  if (data.data.status === 200) {
                    dispatch({type: FETCH_SUCCESS});
                    const getdata = data.data.message;
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: getdata,
                    });
                  } else {
                    dispatch({
                      type: CHANGE_MOBILENO_RES,
                      payload: data.data.message,
                    });
                  }
                })
                .catch((error) => {
                  dispatch({type: FETCH_ERROR, payload: error.message});
                  dispatch(
                    onWriteCloudWatchLog(
                      streamname,
                      filename,
                      'onGetPendingPatInfn',
                      '0',
                      error,
                    ),
                  );
                });
            } catch (err) {
              dispatch(fetchError(err.response.message));
              dispatch(
                onWriteCloudWatchLog(
                  streamname,
                  filename,
                  'onGetPendingPatInfn',
                  '0',
                  err,
                ),
              );
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPendingPatInfn',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPendingPatInfn',
          '0',
          err,
        ),
      );
    }
  };
};

// Delete eRx Medicine details
export const onDeleteMedicineDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/pateRxMgmt/deleteMedicineData', value)
        .then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});

            dispatch({
              type: DELETE_MEDCINE_SUCCESS_MSG,
              payload: data.data.message,
            });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onDeleteMedicineDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onDeleteMedicineDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Get Unit data
export const onGetUnits = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/pateRxMgmt/getMargUomjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/pateRxMgmt/getMargUom', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = data.data.body.map((e, i) => {
                    return {
                      title: e.unit,
                    };
                  });
                  dispatch({
                    type: UNIT_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetUnits',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetUnits',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetUnits',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGetUnits', '0', err),
      );
    }
  };
};

// Update Patient details
export const onUpdatePatientDetails = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //Call Update Token  - Get Token
    try {
      jwtAxios
        .post('v1/patientDtls/updatejwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/patientDtls/update', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: PATIENT_SUCCESS_MSG,
                    payload: data.data.message,
                  });
                  dispatch({
                    type: SHOW_MESSAGE,
                    payload: data.data.message,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onUpdatePatientDetails',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onUpdatePatientDetails',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onUpdatePatientDetails',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onUpdatePatientDetails',
          '0',
          err,
        ),
      );
    }
  };
};

// Get InvestigationCategory  data
export const onGetInvestigationCategory = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/searchInvestigationCaetgoryjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/searchInvestigationCaetgory', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var arraydata = [...data.data.body];
                  dispatch({
                    type: INVESTIGATION_CATEGORY_DATA,
                    payload: data.data.body,
                  });
                  dispatch({
                    type: TAG_CATEGORY_DATA,
                    payload: arraydata,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetInvestigationCategory',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetInvestigationCategory',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetInvestigationCategory',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetInvestigationCategory',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGettodayAppointment = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getTodayAppointmentDetailsJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/docMgmt/getTodayAppointmentDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: TODAY_APPOINTMENT_DATA,
                    payload: data.data.body.todayAppointment,
                  });
                  console.log(data.data.body.todayAppointment, 'body');
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeSearchDiseaseData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGettodayAppointment',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGettodayAppointment',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGettodayAppointment',
          '0',
          err,
        ),
      );
    }
  };
};
export const onGeSearchDiseaseData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/searchDiseaseCaetgoryjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/searchDiseaseCaetgory', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_DISEASE_DATA,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: SEARCH_DISEASE_DATA,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeSearchDiseaseData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeSearchDiseaseData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeSearchDiseaseData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeSearchDiseaseData',
          '0',
          err,
        ),
      );
    }
  };
};
//Search symptoms
export const onGeSearchSymptomsData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/searchSymptomsCaetgoryjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/searchSymptomsCaetgory', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_SYMPTOMS_DATA,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: SEARCH_SYMPTOMS_DATA,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeSearchSymptomsData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeSearchSymptomsData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeSearchSymptomsData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeSearchSymptomsData',
          '0',
          err,
        ),
      );
    }
  };
};

// Get search patiet data  data
export const onGeSearchPatientData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/searchPatientDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/docMgmt/searchPatientData', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_PATIENT_DATA,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: SEARCH_PATIENT_DATA,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeSearchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeSearchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeSearchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeSearchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

// Get search patiet data  data
export const onGeSearchPatientOldData = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/searchPatientOldDataJwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/docMgmt/searchPatientOldData', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: SEARCH_PATIENT_OLDDATA,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: SEARCH_PATIENT_OLDDATA,
                    payload: [],
                  });
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeSearchPatientData',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeSearchPatientData',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeSearchPatientData',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGeSearchPatientData',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGeteRxReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/reportDtls/getReportDetailsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/reportDtls/getReportDetails', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  var reports = data.data.body.map(function (e) {
                    var reportdata =
                      e.reportTagId !== null &&
                      e.reportTagId !== undefined &&
                      e.reportTagId !== 0
                        ? {uid: e.reportTagId, category: e.reportCategory}
                        : {};
                    e.reportTagId =
                      e.reportTagId !== null && e.reportTagId !== undefined
                        ? e.reportTagId
                        : 0;
                    return {...e, reportTag: reportdata};
                  });
                  dispatch({
                    type: REPORT_DETAILS,
                    payload: reports,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGeteRxReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGeteRxReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGeteRxReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(streamname, filename, 'onGeteRxReports', '0', err),
      );
    }
  };
};

//get patient list for search dropdown

// Get InvestigationCategory  data
export const onGetPatientList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/onGetPatientListjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/docMgmt/onGetPatientList', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: PATIENT_LISTS,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetPatientList',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetPatientList',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetPatientList',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetPatientList',
          '0',
          err,
        ),
      );
    }
  };
};

export const onGetSearchPatientReports = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    try {
      jwtAxios
        .post('v1/docMgmt/getSearchPatientReportsjwt', value)
        .then((data) => {
          try {
            value['jwtToken'] = data.data.body['token'];
            jwtAxios
              .post('v1/docMgmt/getSearchPatientReports', value)
              .then((data) => {
                if (data.data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: SEARCH_REPORTS_DATA,
                    payload: data.data.body,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'onGetSearchPatientReports',
                    '0',
                    error,
                  ),
                );
              });
          } catch (err) {
            dispatch(fetchError(err.response.message));
            dispatch(
              onWriteCloudWatchLog(
                streamname,
                filename,
                'onGetSearchPatientReports',
                '0',
                err,
              ),
            );
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetSearchPatientReports',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'onGetSearchPatientReports',
          '0',
          err,
        ),
      );
    }
  };
};
